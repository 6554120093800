.thank-you-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin-left: 50px;
}

.thank-you-container-plirio-icon {
  width: 100px;
  height: 100px;
}

.thank-you-header {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
}

.thank-you-btn-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 280px;
}

.thank-you-btn-container button {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 550px) {
  .thank-you-container-plirio-icon {
    align-self: center;
  }
  .thank-you-container {
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .thank-you-btn-container {
    min-width: 100% !important;
  }
}
