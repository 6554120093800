@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap");

.input-container {
  width: 100%;
}

.input {
  background: rgba(116, 116, 128, 0.08);
  border-radius: 10px;
  height: 45px;
  width: 100%;
  border: unset;
}

.input:disabled {
  cursor: not-allowed;
}

.input-label {
  margin-left: 10px;
}

.input-error {
  color: red;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-top: 2px;
  margin-left: 5px;
}

.input-icons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  position: absolute;
  right: 10px;
}

input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: rgba(60, 60, 67, 0.3);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: rgba(60, 60, 67, 0.3);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: rgba(60, 60, 67, 0.3);
}
