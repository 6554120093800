.footer-main-container {
  background: #f2f2f7;
  border-top: 1px solid rgba(60, 60, 67, 0.3);
  padding: 20px 0px 20px 0px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  left: 0;
}

.footer-container1 {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.footer-container2 {
  display: flex;
  height: 100%;
  /* margin-right: 700px; */
}

.confirm-services-btn {
  height: 50px;
  max-width: 257px;
}

.email-confirmed-container {
  display: flex;
  flex-direction: row;
  padding: 0 50px;
  align-items: center;
}

.email-icon {
  position: relative;
  margin-right: 2px;
  top: 1px;
}

.double-check-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.send-again__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
}

.send-again__container img {
  position: relative;
  top: 2px;
}

.send-again__container span {
  color: #007aff !important;
}

.send-again__container:hover {
  cursor: pointer;
  color: #000;
}

@media (max-width: 880px) {
  .footer-container2 {
    display: none;
  }
  .email-confirmed-container {
    display: none;
  }
  .confirm-services-btn {
    min-width: 90%;
    position: relative;
    left: 0;
    height: 60px;
  }

  .footer-container {
    background: none;
    border-top: none;
  }
}
