@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400&family=Source+Sans+Pro&display=swap");

.button {
  background-color: #007aff;
  border-radius: 13px;
  height: 60px;
  width: 100%;
  min-width: 224px;
  color: white;
  font-size: 17px;
  border: unset;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.button-hover:hover {
  background-color: #0062cc;
  cursor: pointer;
}

.btn-value_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btn-value_container img {
  /* margin-left: 30px; */
}

.btn-disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-outlined {
  background-color: white;
  color: #007aff;
  border: 2px solid #007aff;
  letter-spacing: -0.408px;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
}

.btn-outlined:hover {
  background-color: white;
  color: #007aff;
  cursor: pointer;
}

.btn-prop-icon {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
