.main-container {
  max-width: 1500px;
  padding-top: 30px;
  padding-left: 30px;
  display: flex;
  /* margin: 0px auto; */
}

@media (max-width: 880px) {
  .main-container {
    padding-left: 5px;
    padding-top: 20px;
    padding-right: 5px;
  }
}
