.login-container {
  max-width: 600px;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
}

.header-container {
  display: flex;
  align-items: center;
}

.header-icon {
  margin-top: 0;
}

.inputs-container {
  max-width: 358px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login-separator {
  margin-top: 120px;
  padding-right: 40px;
}

@media (max-width: 560px) {
  .header-icon {
    margin-top: 0px;
  }
}

@media (max-width: 560px) {
  .login-separator {
    display: none;
  }
}
