* {
  margin: 0;
  padding: 0;
}

.services-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  /* margin-left: 70px; */
}

.web-component_container {
  padding-bottom: 10px;
}
