.set-password-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  padding-left: 5px;
  padding-right: 5px;
  /* margin: 0 auto; */
}

.header-container {
  display: flex;
  flex-direction: row;
}
