@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400&family=Source+Sans+Pro&display=swap");

.typography {
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #000000;
  word-wrap: break-word;
}

.typography-primary {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
}

.typography-secondary {
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
}

.typography-input {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-left: 5px;
  color: #636366;
}

.typography-card-label {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.364px;
}

.typography-settings-console {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

.typography-settings-options-label {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.typography-link {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: #007aff;
}

.typography-cost-activated-services {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #007aff;
}

.typography-cost-activated-services:hover {
  cursor: pointer;
  text-decoration: underline;
}

.typography-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.typography-cost-header {
  font-weight: 400;
  font-size: 34px;
  line-height: 41px;
}

.typography-cost-header__mobile {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.36px;
}

.typography-cost-price__mobile {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.36px;
}
